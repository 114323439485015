import React from 'react'
import Loadable from 'react-loadable'
import CookieConsent from 'react-cookie-consent'
import { getLanguage } from "services/language";

// CSS
import 'styles/all.scss'

// Components
import Header from './Header'
import ResearchPopup from './ResearchPopup'
// import Footer from './Footer'

const Footer = Loadable({
  loader: () => import('./Footer'),
  loading() {
    return <div></div>
  },
})

export const LayoutContext = React.createContext({
  popup: false,
  setPopup: (bool) => bool,
})

const Layout = ({ children }) => {
  const [popup, setPopup] = React.useState('hidden')
  //const language = getLanguage();

  // React.useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     if (window.sessionStorage.getItem('researchPopup') !== 'shown') {
  //       window.sessionStorage.setItem('researchPopup', popup)
  //     }
  //   }
  // }, [popup])

  return (
    <LayoutContext.Provider value={{ popup, setPopup }}>
      <Header />
      {/* {language === 'nl_NL' && (
        <ResearchPopup />
      )} */}
      <>{children}</>
      <Footer />
      <CookieConsent
        acceptOnScroll
        acceptOnScrollPercentage={20}
        location="bottom"
        style={{ backgroundColor: '#a1ce30', color: '#fff' }}
        buttonClasses="d-none"
      >
        <div className="container text-center font-weight-l">
          We gebruiken cookies om ervoor te zorgen dat onze website zo soepel
          mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan
          we er vanuit dat u ermee instemt.
        </div>
      </CookieConsent>
    </LayoutContext.Provider>
  )
}

export default Layout
