import React from 'react'
import { Link } from 'gatsby'

// Libs
import styled from 'styled-components'

// Images
import close from 'img/close.svg'

// Hooks
import useLayout from '../hooks/useLayout'

const StyledResearchPopup = styled.div`
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Popup = styled.div`
  background-color: #00a7e0;
  border-radius: 15px;
  padding: 20px 30px 30px;
  cursor: default;
  max-width: 800px;

  & h2 {
    text-align: center;
    color: #fff;
    font-size: 40px;
  }

  & a {
    background-color: #a1ce30;
    color: #fff;
    padding: 10px 20px;
    border-radius: 9999px;
    font-weight: 800;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`

const ResearchPopup = () => {
  const layout = useLayout()

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        window.sessionStorage.getItem('researchPopup') === 'hidden' ||
        window.sessionStorage.getItem('researchPopup') === null
      ) {
        setTimeout(() => {
          layout.setPopup('show')
        }, 3000)
      }
    }
  }, [])

  return (
    <>
      {layout.popup === 'show' && (
        <StyledResearchPopup onClick={() => layout.setPopup('shown')}>
          <Popup onClick={(e) => e.stopPropagation()}>
            <div className="d-flex justify-content-end">
              <div
                onClick={() => layout.setPopup('shown')}
                className="pb-3"
                style={{ cursor: 'pointer' }}
              >
                <img src={close} />
              </div>
            </div>

            <h2>
              Onderzoek: De Nederlandse (super)jachtbouw: Een zinkend schip?
            </h2>
            <div className="pt-5 d-flex justify-content-center">
              <Link to="/duurzamejachtbouw/">
                Download gratis het Veritec onderzoek
              </Link>
            </div>
          </Popup>
        </StyledResearchPopup>
      )}
    </>
  )
}

export default ResearchPopup
